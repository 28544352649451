/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

@font-face {
    font-family: 'gilroy';
        src: url('../fonts/Gilroy-Light.otf');
    }

@font-face {
    font-family: 'gilroybold';
        src: url('../fonts/Gilroy-ExtraBold.otf');
    }

@font-face {
	font-family: 'gilroySemibold';
		src: url('../fonts/Gilroy-SemiBold.ttf');
	}
@font-face {
	font-family: 'gorditamediumRegular';
		src: url('../fonts/Gordita-Regular.otf');
	}
@font-face {
	font-family: 'gorditamediumBold';
		src: url('../fonts/Gordita-Bold.otf');
}
@font-face {
	font-family: 'gorditamediumMedium';
	src: url('../fonts/Gordita-Medium.otf');
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	font-family: 'gilroy' !important;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	scroll-behavior: smooth;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a {
	text-decoration: none !important;
}

.bold {
	font-family: 'gilroySemibold' !important;
}

.lightbold {
	font-weight: 700;
}

.ui.modal {
	top: calc(40% - 168px);
}

.modal-enter {
	margin: auto;
	background: white;
	width: 96% !important;
	padding: 15px !important;
	border-radius: 10px;
	max-width: 800px;
  }
  .modal-enter .header h3 {
	font-size: 22px;
	color: #202c3c;
	padding: 0 0 30px;
  }
  .modal-enter form {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 20px;
  }
  .modal-enter form .MuiTextField-root {
	width: 48%;
	margin: 0 0 20px 0;
  }
  .modal-enter form .btn-container {
	width: 100%;
	text-align: right;
  }
  .modal-enter form .MuiButton-root {
	margin-right: 0;
  }
  .modal-enter .close {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: 30px;
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 40px;
	background-color: #649ace;
	color: #fff;
	border-radius: 50%;
	cursor: pointer;
  }
  @media only screen and (max-width: 1079px) {
	.modal-enter form .MuiTextField-root {
	  width: 100%;
	}

	.ui.modal {
		top: calc(50% - 267px);
		z-index: 1000000;
	}

	.ui.dimmer {
		z-index: 1000000;
	}
  }

  @media only screen and (max-width: 759px) {
	.ui.modal .scrolling.content {
		max-height: calc(100vh);
		overflow: auto;
	}

	.modal-enter .header h3 {
		font-size: 18px;
		color: #202c3c;
		padding: 0 0 10px;
	}

	.modal-enter {
		padding: 0 !important;
		margin: 0;
		width: 100%;
	}

	.modal-enter .content p {
		font-size: 16px;
	}

	.modal-enter form .MuiTextField-root {
		width: 100%;
		margin: 0 0 0px 0;
	}

	.modal-enter form {
		margin: 0;
	  }

	.modal-enter form .btn-container {
		margin-top: 10px;
	}
  }